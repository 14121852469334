<script>
import {
  reclaimByIdComputed,
  reclaimByIdMethods,
  reclaimByIdMutations,
} from '@state/helpers/reclaimById'
import BackButton from '@components/back-button/back-button'
import RECLAIM_STATUSES from '@constants/reclaimStatuses'
import ExpandableCategories from '@components/expandable-categories/expandable-categories'
import formatDate from '@utils/format-date'
import { getSavedStateSession } from '@utils/storage'
import { lunadisPayOrigin } from '@constants/origins'
import EnterpriseOriginToggle from '@components/enterprise-origin-toggle/enterprise-origin-toggle.vue'

export default {
  components: { ExpandableCategories, BackButton, EnterpriseOriginToggle },
  data() {
    return {
      RECLAIM_STATUSES,
      formatDate,
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
      lunadisPayOrigin,
    }
  },
  computed: {
    ...reclaimByIdComputed,
    reclaimStatus() {
      return this.currentReclaim ? this.currentReclaim.status : ''
    },
    reclaimCurrency() {
      return this.currentReclaim ? this.currentReclaim.currency : null
    },
  },

  beforeMount() {
    if (!this.categories) this.getCategories()
    if (!this.invoices) this.getInvoices()
    this.getSingleReclaim(this.$route.params.uuId)
    this.getCategoryOverview(this.$route.params.uuId)
    this.SET_CATEGORY(null)
    this.SET_PAGINATION({
      ...this.pagination,
      page: 1,
      pageSize: 10,
    })
  },
  methods: {
    ...reclaimByIdMethods,
    ...reclaimByIdMutations,
    getQuarterRange(period) {
      if (!this.currentReclaim) return null
      const YearStart = formatDate(new Date(this.currentReclaim.year, 0, 1))
      const Q1End = formatDate(new Date(this.currentReclaim.year, 2, 31))
      const Q2End = formatDate(new Date(this.currentReclaim.year, 5, 30))
      const Q3End = formatDate(new Date(this.currentReclaim.year, 8, 30))
      const Q4End = formatDate(new Date(this.currentReclaim.year, 11, 31))
      const ranges = {
        Q1: `${YearStart} - ${Q1End}`,
        Q2: `${YearStart} - ${Q2End}`,
        Q3: `${YearStart} - ${Q3End}`,
        Q4: `${YearStart} - ${Q4End}`,
      }
      return ranges[period]
    },
    selectCategory(category) {
      this.SET_CATEGORY(category)
      this.updateExpenses()
    },
    setPage(page) {
      this.SET_PAGINATION({ ...this.pagination, page })
      this.updateExpenses()
    },
    updateExpenses() {
      if (this.category) {
        this.getExpenses({
          uuId: this.currentReclaim.id,
          category: this.category,
          page: this.pagination.page,
          page_size: this.pagination.pageSize,
        })
      }
    },
    cancelReclaimHandler() {
      this.$amplitude.getInstance().logEvent('CANCEL_RECLAIM', {
        enterpriseId: this.enterpriseId,
        reclaimId: this.currentReclaim.id,
      })
      this.deleteReclaim(this.currentReclaim.id)
    },
    getStatusClass(string) {
      return string.replace('_', '').toLowerCase()
    },
  },
}
</script>

<template>
  <div>
    <div :class="$style.overview">
      <div :class="[$style.flexRow, $style.leftHeaderRow]">
        <div :class="$style.flexRow">
          <BackButton :class="$style.backButton" @click="$router.go(-1)" />
          <h1 :class="$style.title"
            >{{ $t(`ALPHA3_COUNTRY.${$route.params.country}`) }},
            {{ getQuarterRange(currentReclaim && currentReclaim.period) }}
          </h1>
        </div>
        <!-- Status -->
        <v-flex
          :class="[$style.statusTab, $style[getStatusClass(reclaimStatus)]]"
          >{{
            $t(
              RECLAIM_STATUSES[reclaimStatus]
                ? RECLAIM_STATUSES[reclaimStatus].label
                : 'SUBMITTED'
            )
          }}
        </v-flex>
      </div>

      <div :class="$style.rightHeaderRow">
        <EnterpriseOriginToggle :origin="lunadisPayOrigin">
          <v-flex>
            <pre data-test-id="reclaim-status" :status="reclaimStatus">{{
              reclaimStatus
            }}</pre>
            <div
              v-if="reclaimStatus !== 'SUBMITTED'"
              :class="$style.alert"
              data-test-id="expenses-submitted-notice"
            >
              {{ $t('RECLAIM_EXPENSES_SUBMITTED') }}
            </div>
          </v-flex>
        </EnterpriseOriginToggle>
        <v-btn
          v-if="reclaimStatus === 'SUBMITTED'"
          class="btn primary-btn reclaim pink darken-2"
          :loading="deletingInProgress"
          :disabled="isReadOnlyUser"
          @click="cancelReclaimHandler"
          >{{ $t('CANCEL_RECLAIM') }}</v-btn
        >
        <div v-else :class="$style.locked"> </div>
      </div>
    </div>
    <div :class="[$style.tabRow, $style.flexRow]">
      <div :class="[$style.tabActive, $style.flexRow]">
        <img
          :src="require(`../../assets/flags/${$route.params.country}.png`)"
          :alt="$t(`ALPHA3_COUNTRY.${$route.params.country}`)"
          loading="lazy"
          width="18"
          height="14"
        />

        <p>{{
          $t('ALL_EXPENSES_FOR', {
            country: $t(`ALPHA3_COUNTRY.${$route.params.country}`),
          })
        }}</p></div
      >
      <div :class="$style.flexRow">
        <div :class="[$style.tabItem, $style.flexRow]"
          ><span :class="$style.tabTitle">{{ $t('TOTAL_EXPENSES') }}</span>
          {{ currentReclaim && currentReclaim.expenses.length }}
        </div>
        <div
          v-if="
            currentReclaim && reclaimCurrency && currentReclaim.amount_claimed
          "
          :class="$style.tabItem"
          ><span :class="$style.tabTitle">{{
            $t('TOTAL_POTENTIAL_RECLAIM_AMOUNT')
          }}</span>
          {{ $tn(currentReclaim.amount_claimed.toFixed(2)) }}
          {{ reclaimCurrency }}</div
        >
      </div>
    </div>
    <div :class="$style.expandableCategories">
      <ExpandableCategories
        :categories="categories"
        :category-overview="categoryOverview"
        :category="category"
        :select-category="selectCategory"
        :expenses="expenses"
        :pagination="pagination"
        :set-page="setPage"
        :format-date="formatDate"
        :get-expense="getExpense"
        :loading="getReclaimExpensesInProgress || categoryOverviewInProgress"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

h1 {
  font-size: 26px;
}
.overview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 10px 40px 60px;
  color: white;
  background: $dark;
}

.leftHeaderRow,
.rightHeaderRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.overview .alert {
  width: 500px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-align: right;
  background-color: transparent;
}

// This should be removed when API task is complete, note the spelling mistake of "MANUEL REVIEW"
.reviewfile,
.reviewiban,
.review,
.autoreview,
.manueleview {
  background-color: $in-review;
}

.returned {
  background-color: $update-needed;
}

.approved,
.sent {
  background-color: $sent-to-authorities;
}

.submitted {
  background-color: $submitted;
}

.reclaimed {
  background-color: $reclaimed;
}

.statusTab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 80px;
  padding: 2px 10px;
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
  border-radius: 4px;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.backButton {
  display: block;
  margin: 0;
}

.tabRow {
  align-items: center;
  justify-content: space-between;
  margin: -48px 40px;
  background: rgba(lighten($dark, 4%), 0.9);
  border-top-right-radius: 8px;
}

.tabTitle {
  margin: 0 5px;
  font-weight: initial;
  color: $grey;
}

.tabItem {
  margin: 0 20px;
  font-weight: bold;
  color: white;
}

.title {
  color: #fff;
}

.expandableCategories {
  margin: 55px 0 0 0;
}

.tabActive {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 3px 34px 0 28px;
  margin-top: 3px;
  line-height: 50px;
  color: $dark;
  background: white;
  border-radius: 10px 10px 0 0;
  p {
    margin: 3px 0 0 10px;
    font-size: 16px;
    font-weight: bold;
    color: $dark;
  }
}
.locked {
  display: block;
  width: 45px;
  height: 45px;
  padding: 5px;
  background: $alert-error;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='19' height='23' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16.7 9.625h-1.8v-3.75C14.9 2.768 12.482.25 9.5.25S4.1 2.768 4.1 5.875v3.75H2.3c-.994 0-1.8.84-1.8 1.875v9.375c0 1.036.806 1.875 1.8 1.875h14.4c.994 0 1.8-.84 1.8-1.875V11.5c0-1.036-.806-1.875-1.8-1.875zm-5.4 0H7.7v-3.75C7.7 4.839 8.506 4 9.5 4s1.8.84 1.8 1.875v3.75z' fill='%23FFF' fill-rule='nonzero'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 19px 23px;
  border-radius: 50%;
}

.status {
  display: block;
  font-size: 14px;
  font-style: italic;
}
</style>
