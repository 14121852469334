import { mapState, mapActions, mapMutations } from 'vuex'

export const reclaimByIdComputed = {
  ...mapState('reclaim', {
    currentReclaim: (state) => state.currentReclaim,
    getReclaimExpensesInProgress: (state) => state.getReclaimExpensesInProgress,
    getReclaimExpensesError: (state) => state.getReclaimExpensesError,
    expenses: (state) => state.expenses,
    deletingInProgress: (state) => state.deletingInProgress,
    pagination: (state) => state.pagination,
    category: (state) => state.category,
    categoryOverview: (state) => state.categoryOverview,
  }),
  ...mapState('general', {
    categories: (state) => state.categories,
  }),
  ...mapState('expense', {
    isExpenseVisible: (state) => state.isExpenseVisible,
  }),
  ...mapState('expensesByCountry', {
    categoryOverviewInProgress: (state) => state.categoryOverviewInProgress,
  }),
  ...mapState('reclaimsOverview', {
    invoices: (state) => state.invoices,
  }),
}

export const reclaimByIdMethods = {
  ...mapActions('reclaim', [
    'getCategoryOverview',
    'getExpenses',
    'getSingleReclaim',
    'deleteReclaim',
  ]),
  ...mapActions('general', ['getCategories']),
  ...mapActions('expense', ['getExpense']),
  ...mapActions('reclaimsOverview', ['getInvoices']),
}

export const reclaimByIdMutations = {
  ...mapMutations('reclaim', ['SET_CATEGORY', 'SET_PAGINATION']),
}
