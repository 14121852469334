<script>
import Layout from '@layouts/main/main'
import i18n from '@src/i18n'
import ReclaimById from '@components/reclaim-by-id/reclaim-by-id'

export default {
  page: {
    title: i18n.t('RECLAIM'),
    meta: [{ name: 'description', content: 'Reclaim' }],
  },
  components: { Layout, ReclaimById },
}
</script>

<template>
  <Layout>
    <ReclaimById />
  </Layout>
</template>
